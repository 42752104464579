import React, { useEffect } from "react";
import Navbar from "../navbar";
import Footer from "../footer";
import CTA from "../CTA";
import blog7Image from "./Assets/7.jpg";
import { useNavigate } from "react-router-dom";

const Blog7 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* Hero Container with Background */}
      <div className="bg-gray-50 pt-20 pb-16">
        {/* Category and Title Section */}
        <div className="pt-32 pb-8 max-w-4xl mx-auto px-4">
          <div className="mb-4">
            <button
              onClick={() => navigate("/blog")}
              className="text-sm font-medium text-[#E54C4C] hover:text-[#ff6b6b] transition-colors duration-200 flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
              Back to Blogs
            </button>
          </div>
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-4">
            Blitzo's Green Revolution: Driving Towards a Sustainable London
          </h1>
          <div className="text-sm text-gray-600">
            <span>12 November 2024</span>
            <span className="mx-2">•</span>
            <span>London</span>
          </div>
        </div>

        {/* Featured Image */}
        <div className="max-w-4xl mx-auto px-4 mb-16">
          <div className="aspect-[3/2] relative overflow-hidden rounded-lg bg-white shadow-lg">
            <img
              src={blog7Image}
              alt="Sustainable Delivery"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </div>

      {/* Blog Content */}
      <div className="max-w-3xl mx-auto px-4 py-16">
        <article className="prose lg:prose-xl">
          <p className="text-gray-700 text-lg leading-relaxed">
            Sustainability isn't just an option—it's a responsibility. In a
            world grappling with climate change and environmental challenges,
            Blitzo is proud to play its part in London's green revolution. By
            adopting eco-friendly practices across our operations, we are paving
            the way for a cleaner, greener future.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Our Eco-Friendly Initiatives
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed mb-8">
            At Blitzo, we've implemented a range of sustainability measures to
            minimize our environmental impact while maintaining the speed and
            efficiency our customers expect:
          </p>

          <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-8 rounded-xl shadow-sm mb-8">
            <div className="space-y-8">
              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">🚲</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    E-Bike Riders
                  </h4>
                  <p className="text-gray-600">
                    We actively recruit riders who use e-bikes, significantly
                    reducing emissions associated with traditional delivery
                    vehicles. This eco-friendly mode of transportation is
                    perfect for navigating London's bustling streets while
                    staying green.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">🗺️</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Efficient Routing
                  </h4>
                  <p className="text-gray-600">
                    By leveraging advanced route optimization, we minimize
                    unnecessary travel, cutting down fuel usage and carbon
                    emissions. This not only helps the environment but also
                    ensures faster deliveries.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">♻️</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Sustainable Packaging
                  </h4>
                  <p className="text-gray-600">
                    Blitzo encourages the use of eco-friendly materials for
                    packaging, promoting waste reduction and supporting a
                    circular economy.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Contributing to Global Goals
          </h2>

          <div className="bg-gray-50 p-8 rounded-xl my-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">
              UN Sustainable Development Goals
            </h3>
            <div className="space-y-6">
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">11</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Sustainable Cities and Communities
                  </h4>
                  <p className="text-gray-600">
                    By reducing pollution and promoting green logistics, we're
                    contributing to the creation of cleaner, more sustainable
                    urban environments.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">12</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Responsible Consumption and Production
                  </h4>
                  <p className="text-gray-600">
                    Encouraging sustainable packaging and efficient resource use
                    ensures we're minimizing waste and maximizing impact.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">13</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Climate Action
                  </h4>
                  <p className="text-gray-600">
                    Every initiative we undertake is a step towards combating
                    climate change and reducing our carbon footprint.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Delivering More Than Packages
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Blitzo is committed to delivering positive change along with every
            package. Sustainability isn't just a buzzword for us—it's an
            integral part of our mission to create a better tomorrow. Through
            our green practices, we're not only supporting London's
            environmental goals but also setting new standards for the logistics
            industry.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Join the Green Revolution
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Every delivery you make with Blitzo supports a more sustainable
            future. Download the Blitzo app today to join us in driving towards
            a greener London. Together, we can make a difference—one delivery at
            a time.
          </p>

          <div className="bg-[#E54C4C] bg-opacity-5 p-6 rounded-lg mt-8">
            <p className="text-xl font-semibold text-[#E54C4C] text-center">
              Blitzo: Built for the speed and unpredictability of modern life.
            </p>
          </div>
        </article>
      </div>

      <CTA />
      <Footer />
    </div>
  );
};

export default Blog7;
