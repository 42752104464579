import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Vogue from "../Images/Brands/Vogue_logo.png";
import harper from "../Images/Brands/Harpers-Bazaar-logo.png";
import cp from "../Images/Brands/CP_Logo.png";
import gq from "../Images/Brands/GQ.png";
import crackMag from "../Images/Brands/CrackLogo.png";

const Header = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
`;
const Container = styled.div`
  text-align: center;
  margin-bottom: 0; // Remove the large bottom margin
  height: 160px; // Increase container height
  display: flex;
  flex-direction: column;
`;

const BrandContainer = styled.div`
  position: relative;
  width: 100%;
  height: 120px; // Increase height for logos
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible; // Allow overflow for full logo visibility

  img {
    position: absolute;
    width: 180px; // Larger width for better visibility
    height: 100px; // Larger height
    object-fit: contain;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  img.active {
    opacity: 1;
  }
`;

const BrandSlider = () => {
  const brands = [
    { src: Vogue, alt: "Vogue" },
    { src: harper, alt: "Harper's Bazaar" },
    { src: cp, alt: "CP Concept" },
    { src: gq, alt: "British GQ" },
    { src: crackMag, alt: "Crack Magazine" },
  ];

  const [currentBrand, setCurrentBrand] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBrand((prevBrand) => (prevBrand + 1) % brands.length);
    }, 3000); // 2 seconds visible + 1 second transition

    return () => clearInterval(interval);
  }, [brands.length]);

  return (
    <Container>
      <Header>We work with stylists from</Header>
      <BrandContainer>
        {brands.map((brand, index) => (
          <img
            key={brand.alt}
            src={brand.src}
            alt={brand.alt}
            className={index === currentBrand ? "active" : ""}
          />
        ))}
      </BrandContainer>
    </Container>
  );
};

export default BrandSlider;
