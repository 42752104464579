import React, { useEffect } from "react";
import Navbar from "../navbar";
import Footer from "../footer";
import CTA from "../CTA";
import blog4Image from "./Assets/4.jpeg";
import { useNavigate } from "react-router-dom";

const Blog4 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* Hero Container with Background */}
      <div className="bg-gray-50 pt-20 pb-16">
        {/* Category and Title Section */}
        <div className="pt-32 pb-8 max-w-4xl mx-auto px-4">
          <div className="mb-4">
            <button
              onClick={() => navigate("/blog")}
              className="text-sm font-medium text-[#E54C4C] hover:text-[#ff6b6b] transition-colors duration-200 flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
              Back to Blogs
            </button>
          </div>
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-4">
            How Blitzo Is Setting New Standards Of Delivery
          </h1>
          <div className="text-sm text-gray-600">
            <span>20 November 2024</span>
            <span className="mx-2">•</span>
            <span>London</span>
          </div>
        </div>

        {/* Featured Image */}
        <div className="max-w-4xl mx-auto px-4 mb-16">
          <div className="aspect-[3/2] relative overflow-hidden rounded-lg bg-white shadow-lg">
            <img
              src={blog4Image}
              alt="Modern Delivery Standards"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </div>

      {/* Blog Content */}
      <div className="max-w-3xl mx-auto px-4 py-16">
        <article className="prose lg:prose-xl">
          <p className="text-gray-700 text-lg leading-relaxed">
            Gone are the days when delivery services meant waiting hours or even
            days for your package to arrive. In a city as dynamic as London,
            speed and efficiency are non-negotiable. That's where Blitzo comes
            in—a courier service designed to keep up with the pace of modern
            life. From our groundbreaking features to our customer-first
            approach, Blitzo is redefining the standard for same-day deliveries.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            What Sets Blitzo Apart?
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed mb-8">
            At Blitzo, we've built our service around the needs of our
            customers, offering solutions that save time, reduce stress, and
            enhance convenience. Here's how we're changing the game:
          </p>

          <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-8 rounded-xl shadow-sm">
            <div className="space-y-8">
              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">⚡</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    15-Minute Pickup
                  </h4>
                  <p className="text-gray-600">
                    With riders strategically stationed across London, we do our
                    pickups within just 15 minutes of your order. Whether you're
                    in Shoreditch or South Kensington, Blitzo ensures your
                    delivery is in motion almost instantly.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">📍</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Real-Time Tracking
                  </h4>
                  <p className="text-gray-600">
                    Gone are the days of wondering where your package is. With
                    our cutting-edge real-time tracking feature, you'll know
                    your package's exact location and estimated arrival time,
                    giving you peace of mind from start to finish.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">💰</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Affordable Pricing
                  </h4>
                  <p className="text-gray-600">
                    We believe that exceptional service shouldn't come at a
                    premium. With prices starting as low as £3.49, Blitzo makes
                    fast, reliable deliveries accessible to everyone.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Who Can Benefit from Blitzo?
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed mb-8">
            Blitzo is built for anyone and everyone navigating the challenges of
            a busy city life:
          </p>

          <div className="space-y-6">
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                Individuals Managing Last-Minute Errands
              </h3>
              <p className="text-gray-700">
                From forgotten items to urgent documents, Blitzo ensures your
                day stays on track by taking care of your errands with unmatched
                efficiency.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                Stylists and Fashion Professionals
              </h3>
              <p className="text-gray-700">
                Timing is everything in the world of fashion. Whether it's a
                last-minute garment delivery for a photoshoot or an urgent
                accessory drop-off, Blitzo ensures your creative vision is never
                compromised by logistical challenges.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                Small Businesses and Entrepreneurs
              </h3>
              <p className="text-gray-700">
                For those running a business, time is money. Blitzo provides a
                reliable delivery partner, ensuring your operations run smoothly
                and your customers are satisfied.
              </p>
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Why Blitzo Is the Future of Delivery
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Blitzo isn't just a courier service; it's a commitment to innovation
            and customer satisfaction. Our hyperlocal model ensures that we're
            always nearby and ready to meet your delivery needs. By combining
            technology with a human touch, we're able to deliver not just
            packages but also unparalleled peace of mind.
          </p>

          <p className="text-gray-700 text-lg leading-relaxed">
            Ready to experience the future of delivery? Download the Blitzo app
            today and join the thousands of Londoners who have already made the
            switch to a smarter, faster, and more reliable courier service.
          </p>

          <div className="bg-[#E54C4C] bg-opacity-5 p-6 rounded-lg mt-8">
            <p className="text-xl font-semibold text-[#E54C4C] text-center">
              Blitzo: Built for the speed and unpredictability of modern life.
            </p>
          </div>
        </article>
      </div>

      <CTA />
      <Footer />
    </div>
  );
};

export default Blog4;
