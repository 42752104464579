import React, { useEffect } from "react";
import Navbar from "../navbar";
import Footer from "../footer";
import CTA from "../CTA";
import blog1Image from "./Assets/1.JPG";
import { useNavigate } from "react-router-dom";

const Blog1 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* Hero Container with Background */}
      <div className="bg-gray-50 pt-20 pb-16">
        {/* Category and Title Section */}
        <div className="pt-32 pb-8 max-w-4xl mx-auto px-4">
          <div className="mb-4">
            <button
              onClick={() => navigate("/blog")}
              className="text-sm font-medium text-[#E54C4C] hover:text-[#ff6b6b] transition-colors duration-200 flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
              Back to Blogs
            </button>
          </div>
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-4">
            Blitzo: Revolutionising Same-Day Deliveries with Speed and
            Simplicity
          </h1>
          <div className="text-sm text-gray-600">
            <span>28 November 2024</span>
            <span className="mx-2">•</span>
            <span>London</span>
          </div>
        </div>

        {/* Featured Image */}
        <div className="max-w-4xl mx-auto px-4 mb-16">
          <div className="aspect-[3/2] relative overflow-hidden rounded-lg bg-white shadow-lg">
            <img
              src={blog1Image}
              alt="Blitzo Delivery"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </div>

      {/* Blog Content */}
      <div className="max-w-3xl mx-auto px-4 py-16">
        <article className="prose lg:prose-xl">
          <p className="text-gray-700 text-lg leading-relaxed">
            In today's fast-paced world, the demand for swift and reliable
            delivery services has never been higher. Blitzo is at the forefront
            of this revolution, offering same-day deliveries that combine speed
            with simplicity, ensuring that your packages arrive promptly and
            securely. By blending cutting-edge technology with a customer-first
            approach, Blitzo is transforming how Londoners experience courier
            services.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Designed for Everyday Life
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            For everyday users, Blitzo provides an easy and reliable solution to
            manage life's small but essential tasks. Forgot your wallet at home?
            Need to send urgent documents to your office? Blitzo's 15-minute
            pickup ensures that your errands are handled quickly and
            efficiently. Our real-time tracking system keeps you updated on the
            status of your delivery, giving you peace of mind as you go about
            your day.
          </p>
          <p className="text-gray-700 text-lg leading-relaxed">
            Whether it's a forgotten item, a special gift, or a last-minute
            task, Londoners trust Blitzo to make their lives simpler.
          </p>

          <blockquote className="border-l-4 border-[#E54C4C] pl-4 my-8 italic text-gray-700">
            "This company is gonna go a long way, trust me their customer
            support and service are exceptional. Would highly recommend for your
            daily errands."
            <footer className="text-sm mt-2">- Gurpreet</footer>
          </blockquote>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Unmatched Speed and Reliability for Fashion Professionals
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            The fashion industry operates on tight schedules, where delays can
            disrupt photoshoots, runway events, and high-profile campaigns. With
            riders strategically positioned throughout London, we ensure that
            critical items like garments, accessories, and samples are collected
            and delivered without delay.
          </p>
          <p className="text-gray-700 text-lg leading-relaxed">
            Stylists, designers, and PR firms trust Blitzo for its reliability
            and precision. Our advanced real-time tracking system provides you
            with up-to-the-minute updates on your delivery's status, offering
            peace of mind and transparency. Whether it's a couture gown for a
            shoot or last-minute accessories for an event, Blitzo keeps your
            operations on schedule.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Tailored Solutions for Everyone
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed mb-8">
            Understanding the diverse needs of our customers, Blitzo offers
            services designed to simplify logistics for all:
          </p>
          <div className="space-y-8">
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                Multi-Drop Deliveries
              </h3>
              <p className="text-gray-700">
                Perfect for businesses and individuals needing to send multiple
                packages to different locations. Fashion professionals, in
                particular, find this feature invaluable for managing multiple
                errands in one go.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                Fragile and High-Value Items
              </h3>
              <p className="text-gray-700">
                From delicate fabrics to personal valuables, our riders are
                trained to handle every item with care, ensuring they arrive in
                pristine condition.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                Eco-Friendly Options
              </h3>
              <p className="text-gray-700">
                Blitzo's green initiatives, including e-bike deliveries and
                optimized routing, reduce our carbon footprint while ensuring
                speedy service for everyone.
              </p>
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Exceptional Customer Service Across the Board
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            At Blitzo, customer satisfaction is paramount. Our dedicated support
            team is always ready to assist, ensuring a smooth and stress-free
            experience from start to finish.
          </p>

          <blockquote className="border-l-4 border-[#E54C4C] pl-4 my-8 italic text-gray-700">
            "Incredible team, will go above and beyond to make things happen."
            <footer className="text-sm mt-2">- Abigail, Stylist</footer>
          </blockquote>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Trusted by All
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Blitzo's services have become indispensable to a wide range of
            users, from busy professionals to fashion insiders. In the
            fast-paced fashion industry, Blitzo has emerged as a game-changer.
            Stylists and PR Firms rely on our multi-drop feature and real-time
            tracking to manage complex logistics with ease.
          </p>

          <blockquote className="border-l-4 border-[#E54C4C] pl-4 my-8 italic text-gray-700">
            "Super speedy courier service, working as a stylist this app saves
            me so much time and money when prepping for shoots. Will definitely
            be recommending this service to my team and other stylists."
            <footer className="text-sm mt-2">- Jamie, Stylist</footer>
          </blockquote>

          <p className="text-gray-700 text-lg leading-relaxed">
            Blitzo's ability to adapt to varied requirements, from personal
            deliveries to industry-specific needs, has cemented its position as
            a trusted partner across London. By focusing on excellence and
            innovation, we're redefining how courier services are perceived and
            used.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            A Commitment to Excellence
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Blitzo's dedication to providing exceptional service has earned the
            trust and loyalty of clients across London. As a company, we
            continuously innovate to offer faster, more reliable, and
            eco-conscious delivery solutions. Our goal is to simplify the
            logistics process, making it effortless and stress-free for
            everyone.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Experience the Blitzo Difference
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Whether you're managing daily errands, coordinating a photoshoot,
            preparing for a runway show, or simply need a reliable delivery
            partner, Blitzo is here for you. Download the Blitzo app today and
            discover how we're revolutionizing same-day deliveries for
            everyone—from individuals to fashion professionals.
          </p>

          <div className="bg-[#E54C4C] bg-opacity-5 p-6 rounded-lg mt-8">
            <p className="text-xl font-semibold text-[#E54C4C] text-center">
              Blitzo: Built for the speed and unpredictability of modern life.
            </p>
          </div>
        </article>
      </div>

      <CTA />
      <Footer />
    </div>
  );
};

export default Blog1;
