export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return "android";
  }
  if (/iPad|iPhone|iPod/.test(ua)) {
    return "ios";
  }
  return "desktop";
};

export const getStoreLink = () => {
  const device = getDeviceType();
  return device === "android"
    ? "https://play.google.com/store/apps/details?id=com.blitzo&pcampaignid=web_share"
    : "https://apps.apple.com/gb/app/blitzo/id6502848319";
};
