import React, { useEffect } from "react";
import {
  Clock,
  MapPin,
  BikeIcon,
  Shield,
  Smartphone,
  Code,
} from "lucide-react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import CTA from "../components/CTA";
import AOS from "aos";
import "aos/dist/aos.css";
import apiImage from "../Images/API_Business.png";
import mockup from "../Images/mockup.png";
import coverage from "../Images/coverage-london.jpg";

const HowWeWork = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });
  }, []);
  return (
    <div className="bg-white min-h-screen">
      <Navbar />
      <div className="relative bg-gradient-to-br from-white to-gray-100 pt-40 pb-20">
        <div
          className="max-w-7xl mx-auto px-6 lg:px-8 text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1 className="text-3xl md:text-4xl md:text-7xl font-bold text-gray-900 mb-6">
            <span className="block mb-2">How We Work</span>
            <span className="text-[#E54C4C] relative inline-block after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-1 after:bg-[#E54C4C]/20 after:w-full hover:after:bg-[#E54C4C] after:transition-colors">
              Same Day Redefined
            </span>
          </h1>
          <p className="text-xl md:text-2xl text-gray-600 max-w-3xl mx-auto mt-8 leading-relaxed">
            Choose how you want to work with us - through our seamless mobile
            app or via direct integration.
          </p>
        </div>
      </div>

      {/* Delivery Models with Graphics */}
      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-24">
          {" "}
          {/* Increased gap */}
          {/* Model 1: Direct Consumer */}
          <div className="flex flex-col">
            <div className="h-[380px] bg-transparent rounded-lg mb-12">
              {" "}
              {/* Increased height and margin */}
              {/* Placeholder for custom graphic showing app booking flow */}
              <div className="w-full h-full flex items-center justify-center">
                <img
                  src={mockup}
                  alt="Mobile App Mockup"
                  className="object-contain w-full h-full hover:scale-175 transition-transform duration-300 scale-150"
                />
              </div>
            </div>
            <div className="space-y-6">
              {" "}
              {/* Added container for text content */}
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900">
                Mobile App
              </h2>{" "}
              {/* Increased text size */}
              <p className="text-xl text-gray-600">
                Our convenient mobile app lets you book instant deliveries.
                Perfect for stylists, small boutiques, and urgent fashion
                deliveries.
              </p>
              <ul className="space-y-4 text-lg text-gray-600">
                {" "}
                {/* Increased list text size */}
                <li className="flex items-start">
                  <span className="text-[#E54C4C] mr-2">•</span>
                  Book instantly and we'll collect within 15 minutes
                </li>
                <li className="flex items-start">
                  <span className="text-[#E54C4C] mr-2">•</span>
                  Prices start from just £3.49
                </li>
                <li className="flex items-start">
                  <span className="text-[#E54C4C] mr-2">•</span>
                  Real-time tracking and photo proof of delivery
                </li>
                <li className="flex items-start">
                  <span className="text-[#E54C4C] mr-2">•</span>
                  Schedule collections for whenever you need
                </li>
              </ul>
            </div>
          </div>
          {/* Model 2: Business Integration */}
          <div className="flex flex-col">
            <div className="h-[380px] bg-transparent rounded-lg mb-12">
              {/* Placeholder for custom graphic showing integration options */}
              <div className="w-full h-full flex items-center justify-center">
                <img
                  src={apiImage}
                  alt="API Integration"
                  className="object-contain w-full h-full transition-transform duration-300"
                />
              </div>
            </div>
            <div className="space-y-6">
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900">
                Business Integration
              </h2>
              <p className="text-xl text-gray-600">
                Seamlessly integrate Blitzo into your business operations with
                our API and platform integrations.
              </p>
              <ul className="space-y-4 text-lg text-gray-600">
                <li className="flex items-start">
                  <span className="text-[#E54C4C] mr-2">•</span>
                  Direct API access for custom integration.
                </li>
                <li className="flex items-start">
                  <span className="text-[#E54C4C] mr-2">•</span>
                  Ready-to-use plugins for Shopify, Wix, and more
                </li>
                <li className="flex items-start">
                  <span className="text-[#E54C4C] mr-2">•</span>
                  Fulfillment centres for e-commerce needs
                </li>
                <li className="flex items-start">
                  <span className="text-[#E54C4C] mr-2">•</span>
                  Custom rider training including technical skills
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Operating Hours */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-start gap-12">
            <div className="md:w-1/3">
              <Clock className="text-[#E54C4C] mb-4" size={32} />
              <h2 className="text-2xl font-bold mb-4">Operating Hours</h2>
            </div>
            <div className="md:w-2/3">
              <p className="text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent mb-4">
                We're working pretty much always.
              </p>
              <div className="bg-white rounded-xl p-8 shadow-sm hover:shadow-xl transition-all duration-300 border border-gray-100">
                <p className="text-xl text-gray-700 font-medium mb-4">
                  Blitzo operates between 7am and 11pm, 7 days a week.
                </p>
                <p className="text-gray-600">
                  If you need a delivery outside of these hours, please get in
                  touch, we'll do our best to make it happen!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Operating Area */}
      <div className="py-20">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-start gap-12">
            <div className="md:w-1/3">
              <MapPin className="text-[#E54C4C] mb-4" size={32} />
              <h2 className="text-2xl font-bold mb-4">Operating Area</h2>
            </div>
            <div className="md:w-2/3">
              <p className="text-3xl font-bold text-gray-900 mb-4 leading-tight">
                Delivering to <span className="text-[#E54C4C]">every area</span>{" "}
                in London.
              </p>
              <div className="h-80 bg-gray-100 rounded-lg mb-6">
                {/* Placeholder for London coverage map */}
                <div className="w-full h-full flex items-center justify-center text-gray-400">
                  <img
                    src={coverage}
                    alt="London Coverage"
                    className="h-full object-cover"
                  />
                </div>
              </div>
              <p className="text-sm text-gray-500">
                Full postcode list available on request
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Couriers & Vehicles */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-start gap-12">
            <div className="md:w-1/3">
              <BikeIcon className="text-[#E54C4C] mb-4" size={32} />
              <h2 className="text-2xl font-bold mb-4">Our Fleet</h2>
            </div>
            <div className="md:w-2/3">
              <p className="text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent mb-6">
                We're proud to have London's best self-employed Riders fleet.
              </p>
              <div className="bg-white rounded-xl p-8 shadow-sm hover:shadow-xl transition-all duration-300 border border-gray-100">
                <p className="text-xl text-gray-700 font-medium mb-6">
                  Our riders are carefully selected and undergo comprehensive
                  training in hospitality and professional conduct. We ensure
                  they understand the importance of handling high-value fashion
                  items with care.
                </p>
                <p className="text-xl font-semibold text-gray-800 mb-4">
                  To maintain the highest standards, we:
                </p>
                <ul className="space-y-4">
                  <li className="flex items-center space-x-3">
                    <span className="text-[#E54C4C] text-xl">•</span>
                    <span className="text-gray-700 text-lg">
                      Pay our riders well above market rates
                    </span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <span className="text-[#E54C4C] text-xl">•</span>
                    <span className="text-gray-700 text-lg">
                      Provide thorough training in customer service
                    </span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <span className="text-[#E54C4C] text-xl">•</span>
                    <span className="text-gray-700 text-lg">
                      Ensure professional appearance and conduct
                    </span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <span className="text-[#E54C4C] text-xl">•</span>
                    <span className="text-gray-700 text-lg">
                      Use only eco-friendly electric vehicles
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Protection */}
      <div className="py-20">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-start gap-12">
            <div className="md:w-1/3">
              <Shield className="text-[#E54C4C] mb-4" size={32} />
              <h2 className="text-2xl font-bold mb-4">Buyer Protection</h2>
            </div>
            <div className="md:w-2/3">
              <p className="text-3xl font-bold text-gray-900 mb-4">
                Every delivery is{" "}
                <span className="text-[#E54C4C]">protected</span>, at no extra
                cost.
              </p>
              <div className="bg-white rounded-xl p-8 shadow-sm hover:shadow-xl transition-all duration-300 border border-gray-100">
                <p className="text-xl text-gray-700 font-medium mb-4">
                  We take the safety of your items seriously. All deliveries are
                  automatically covered by our comprehensive protection policy.
                </p>
                <p className="text-gray-500 italic">
                  Subject to our standard terms and conditions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <CTA />

      <Footer />
    </div>
  );
};

export default HowWeWork;
