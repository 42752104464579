import React from "react";
import { Building, BikeIcon, Handshake } from "lucide-react";
import logoImage from "../Images/logo.png";
import Navbar from "./navbar";
import FIA1 from "../Images/Founders_In_Action.jpeg";
import FIA2 from "../Images/FIA_2.jpeg";
import FIA3 from "../Images/FIA_3.jpeg";
import sundarPFP from "../Images/sundar-pfp.png";
import arjunPFP from "../Images/Arjun-pfp.jpg";
import Footer from "./footer";
import CTA from "./CTA";

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <div className="bg-gray-50 min-h-screen">
        {/* Hero Section */}
        <div className="relative bg-gradient-to-br from-white to-gray-100 pb-20 pt-40">
          <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
            <img
              src={logoImage}
              alt="Blitzo"
              className="h-24 w-auto mx-auto mb-6"
            />
            <h1 className="text-3xl md:text-5xl font-bold text-gray-900 mb-4">
              Building London's Same-Day Fashion Courier
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              What started as a general purpose tech-enabled same day courier
              quickly evolved into something more meaningful - a solution to one
              of fashion's biggest logistical challenges.
            </p>
          </div>
        </div>
        {/* Mission, Story, Vision Sections */}
        <div className="max-w-7xl mx-auto px-6 lg:px-8 pb-16 grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Our Story */}
          <div className="bg-white shadow-lg p-8 rounded-xl border border-gray-200 transition-transform hover:-translate-y-1">
            <div className="w-16 h-16 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mb-6">
              <Building className="text-[#E54C4C]" size={32} />
            </div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Our Story</h2>
            <p className="text-gray-700">
              Everything clicked when we saw stylists struggling with garment
              deliveries. Using multiple Ubers for fashion shoots, paying
              premium rates for outdated same day services, and coordinating
              complex logistics was a nightmare. We built Blitzo to solve these
              challenges, starting with the fashion industry we know and love.
            </p>
          </div>

          {/* What We Do */}
          <div
            className="bg-white shadow-lg p-8 rounded-xl border border-gray-200 transition-transform hover:-translate-y-1"
            data-aos-delay="100"
          >
            <div className="w-16 h-16 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mb-6">
              <BikeIcon className="text-[#E54C4C]" size={32} />
            </div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              What We Do
            </h2>
            <p className="text-gray-700">
              We're not just another same day courier service. We specialize in
              high value logistics, handling everything from urgent sample
              returns to complex multi-stop deliveries with care and precision.
              Your high-value items are always in safe hands with us as we use a
              signature blend of AI and human expertise.
            </p>
          </div>

          {/* Vision */}
          <div
            className="bg-white shadow-lg p-8 rounded-xl border border-gray-200 transition-transform hover:-translate-y-1"
            data-aos-delay="200"
          >
            <div className="w-16 h-16 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mb-6">
              <Handshake className="text-[#E54C4C]" size={32} />
            </div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Our Vision
            </h2>
            <p className="text-gray-700">
              Our vision is to enable anything high value to be delivered
              on-demand, in minutes, at a wallet-friendly price. We're starting
              with fashion, but our technology is built to scale across
              industries, from luxury goods to retail.
            </p>
          </div>
        </div>
        {/* Founders in Action Section */}
        <div className="bg-white py-20">
          <div className="max-w-7xl mx-auto px-6 lg:px-8">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">
                A Glimpse of our Founders in Action
              </h2>
              <p className="text-xl text-gray-700 leading-relaxed">
                We don't just build technology and analyse spreadsheets– we live
                and breathe our mission every day. From personally handling
                urgent fashion show deliveries at 7 AM to trying to carry large
                boxes of garments across London in the rain without a car, we're
                here on the ground, understanding your challenges and crafting
                solutions that truly work. This isn't just a startup for us;
                it's our passion to transform how high-value items move around
                cities.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
              {/* Action Shot 1 */}
              <div className="group relative rounded-xl overflow-hidden shadow-lg h-[600px]">
                {" "}
                {/* Fixed height */}
                <div className="w-full h-full bg-gray-200">
                  <img
                    src={FIA1}
                    alt="Sundar & Arjun doing large multidrop deliveries"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-6">
                  <p className="text-white text-sm">
                    Personally delivering a large multi-drop delivery for a
                    celebrity stylist
                  </p>
                </div>
              </div>

              {/* Action Shot 2 */}
              <div className="group relative rounded-xl overflow-hidden shadow-lg h-[600px]">
                <div className="w-full h-full bg-gray-200">
                  <img
                    src={FIA2}
                    alt="Sundar & Arjun making content for Blitzo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-6">
                  <p className="text-white text-sm">
                    Creating content to showcase the Blitzo experience
                  </p>
                </div>
              </div>

              {/* Action Shot 3 */}
              <div className="group relative rounded-xl overflow-hidden shadow-lg h-[600px]">
                <div className="w-full h-full bg-gray-200">
                  <img
                    src={FIA3}
                    alt="Sundar & Arjun struggling with large boxes"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-6">
                  <p className="text-white text-sm">
                    Trying to carry large boxes of garments across London in the
                    rain without a car
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-12 text-center max-w-2xl mx-auto">
              <p className="text-gray-600 italic">
                "Every delivery is personal to us. We're not just building a
                fashion courier; we're crafting experiences that make your work
                easier. When you trust us with your valuable items, you're not
                just getting a delivery – you're getting two founders who
                genuinely care about your brand's success."
              </p>
              <p className="mt-4 text-[#E54C4C] font-medium">
                - Sundar & Arjun
              </p>
            </div>
          </div>
        </div>

        {/* Team Section */}
        <div className="bg-gray-100 py-20">
          <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-8">
              Meet Our Founders
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-12">
              Two friends from Kings College London, united by our passion for
              solving complex problems and making life easier for others.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-4xl mx-auto">
              {/* Sundar */}
              <div className="bg-white p-8 rounded-xl shadow-lg">
                <div className="h-36 w-36 rounded-full bg-gray-300 mx-auto mb-6">
                  <img
                    src={sundarPFP}
                    alt="Sundar Arvind"
                    className="h-full w-full object-cover rounded-full"
                  />
                </div>
                <h3 className="text-xl font-semibold">Sundar Arvind</h3>
                <p className="text-[#E54C4C] font-medium mb-4">Co-CEO</p>
                <p className="text-gray-600">
                  A former professional tennis player ranked in India's top 3,
                  and one of the youngest music producers signed by Spinnin
                  Records, Sundar brings his creative drive and analytical
                  thinking to every challenge.
                </p>
              </div>

              {/* Arjun */}
              <div className="bg-white p-8 rounded-xl shadow-lg">
                <div className="h-36 w-36 rounded-full bg-gray-300 mx-auto mb-6">
                  <img
                    src={arjunPFP}
                    alt="Arjun"
                    className="h-full w-full object-cover rounded-full"
                  />
                </div>
                <h3 className="text-xl font-semibold">Arjun</h3>
                <p className="text-[#E54C4C] font-medium mb-4">Co-CEO</p>
                <p className="text-gray-600">
                  Coming from a family of successful entrepreneurs, Arjun has
                  business in his blood. His fleet management techniques have
                  been instrumental in realising Blitzo's vision of reliable,
                  speedy high value deliveries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CTA />
      <Footer />
    </>
  );
};

export default AboutUs;
