import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import WorkWithUs from "./pages/WorkWithUs";
import WaitlistProvider from "./Contexts/WaitlistProvider";
import Terms from "./components/Terms";
import AboutUs from "./components/AboutUs";
import Tracker from "./pages/Tracker";
import Multidrop from "./components/Blogs/Multidrop";
import HowWeWork from "./pages/HowWeWork";
import Contact from "./pages/Contact";
import Blog from "./components/blog";
import Blog1 from "./components/Blogs/blog1";
import Blog2 from "./components/Blogs/blog2";
import Blog3 from "./components/Blogs/blog3";
import Blog4 from "./components/Blogs/blog4";
import Blog5 from "./components/Blogs/blog5";
import Blog6 from "./components/Blogs/blog6";
import Blog7 from "./components/Blogs/blog7";
import Blog8 from "./components/Blogs/blog8";
import TrackOrder from "./pages/trackOrder";
function AppContent() {
  return (
    <WaitlistProvider>
      {/* {!isTrackerPage && <Navbar />} */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/how-we-work" element={<HowWeWork />} />
        <Route path="/workWithUs" element={<WorkWithUs />} />
        {/* <Route path="/faq" element={<FAQ />} /> */}
        <Route path="/terms" element={<Terms />} />
        {/* <Route path="/stylists" element={<Stylists />} />
        <Route path="/clothing-returns" element={<Returns />} />
        <Route path="/click-collect" element={<ClickAndCollect />} />
        <Route path="/parcel-deliveries" element={<Parcel />} /> */}
        <Route path="/about" element={<AboutUs />} />
        <Route path="/liveOrders/:orderId/:view?" element={<Tracker />} />
        <Route path="/multidrop" element={<Multidrop />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/blog1" element={<Blog1 />} />
        <Route path="/blog/blog2" element={<Blog2 />} />
        <Route path="/blog/blog3" element={<Blog3 />} />
        <Route path="/blog/blog4" element={<Blog4 />} />
        <Route path="/blog/blog5" element={<Blog5 />} />
        <Route path="/blog/blog6" element={<Blog6 />} />
        <Route path="/blog/blog7" element={<Blog7 />} />
        <Route path="/blog/blog8" element={<Blog8 />} />
        <Route path="/track-order" element={<TrackOrder />} />
      </Routes>
      {/* {!isTrackerPage && <Footer />} */}
    </WaitlistProvider>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <AppContent />
      </Router>
    </div>
  );
}

export default App;
