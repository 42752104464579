import React, { useEffect } from "react";
import Navbar from "../navbar";
import Footer from "../footer";
import CTA from "../CTA";
import blog3Image from "./Assets/3.JPG";
import { useNavigate } from "react-router-dom";

const Blog3 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* Hero Container with Background */}
      <div className="bg-gray-50 pt-20 pb-16">
        {/* Category and Title Section */}
        <div className="pt-32 pb-8 max-w-4xl mx-auto px-4">
          <div className="mb-4">
            <button
              onClick={() => navigate("/blog")}
              className="text-sm font-medium text-[#E54C4C] hover:text-[#ff6b6b] transition-colors duration-200 flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
              Back to Blogs
            </button>
          </div>
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-4">
            Meet the Blitzers: The Backbone of Blitzo's Success
          </h1>
          <div className="text-sm text-gray-600">
            <span>22 November 2024</span>
            <span className="mx-2">•</span>
            <span>London</span>
          </div>
        </div>

        {/* Featured Image */}
        <div className="max-w-4xl mx-auto px-4 mb-16">
          <div className="aspect-[3/2] relative overflow-hidden rounded-lg bg-white shadow-lg">
            <img
              src={blog3Image}
              alt="Blitzo Delivery Riders"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </div>

      {/* Blog Content */}
      <div className="max-w-3xl mx-auto px-4 py-16">
        <article className="prose lg:prose-xl">
          <p className="text-gray-700 text-lg leading-relaxed">
            At Blitzo, our riders—called{" "}
            <span className="font-semibold">Blitzers</span>—are the beating
            heart of our operation. They are the ones ensuring that packages are
            delivered on time, every time. But what makes a{" "}
            <span className="font-semibold">Blitzer</span>?
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Rigorous Recruitment for Reliability
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed mb-8">
            Our recruitment process goes beyond the basics. Each potential
            <span className="font-semibold"> Blitzer </span>undergoes thorough
            vetting to ensure they meet our high standards of professionalism
            and trust.
          </p>

          <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-8 rounded-xl shadow-sm">
            <h3 className="text-xl font-bold text-gray-900 mb-6">
              Our Comprehensive Vetting Process
            </h3>
            <div className="space-y-6">
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] font-bold">01</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Anti-Money Laundering (AML) Checks
                  </h4>
                  <p className="text-gray-600">
                    Ensuring compliance and safeguarding against illicit
                    activities
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] font-bold">02</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Right-to-Work Verification
                  </h4>
                  <p className="text-gray-600">
                    Confirming eligibility to work in the UK
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] font-bold">03</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Background Checks
                  </h4>
                  <p className="text-gray-600">
                    Evaluating history to ensure reliability and trustworthiness
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p className="text-gray-700 text-lg leading-relaxed">
            But we don't stop there. Every{" "}
            <span className="font-semibold">Blitzer</span> is trained to handle
            fragile and high-value deliveries with exceptional care, giving our
            customers peace of mind knowing their packages are in safe hands.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Equipped for Excellence
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            <span className="font-semibold">Blitzers</span> are more than just
            riders; they're experts equipped with cutting-edge tools and
            real-time navigation systems. Strategically placed across London,
            they're ready to meet the 15-minute pickup, no matter where you are.
            Whether it's a last-minute garment delivery or an urgent personal
            item, <span className="font-semibold">Blitzers</span> rise to the
            challenge with precision and speed.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            The People Behind the Packages
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed mb-6">
            Our <span className="font-semibold">Blitzers</span> are a diverse
            group of self-employed professionals who choose when and how they
            work. Many come from professional courier backgrounds, bringing
            years of experience navigating London's complex street network.
          </p>

          <div className="bg-gray-50 p-8 rounded-xl my-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">
              What Makes Our Blitzers Special
            </h3>
            <div className="space-y-4">
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">✓</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Professional Experience
                  </h4>
                  <p className="text-gray-600">
                    Long-term experience in courier services, with deep
                    knowledge of London's geography
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">✓</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Entrepreneurial Spirit
                  </h4>
                  <p className="text-gray-600">
                    Self-employed professionals managing their own delivery
                    business
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">✓</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Work-Life Balance
                  </h4>
                  <p className="text-gray-600">
                    Freedom to choose their working hours and delivery zones
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p className="text-gray-700 text-lg leading-relaxed">
            As independent contractors, our{" "}
            <span className="font-semibold">Blitzers</span> enjoy the
            flexibility to build their schedules around their lives, not the
            other way around. Many have been with us since our early days,
            growing their businesses alongside Blitzo's expansion. Their
            experience and dedication to excellence make them true professionals
            in the delivery industry.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Join the <span className="font-semibold">Blitzer</span> Family
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed mb-8">
            Are you looking for a rewarding and dynamic role? Join the Blitzo
            revolution as a <span className="font-semibold">Blitzer</span>!
            We're always on the lookout for driven, reliable individuals who are
            passionate about providing top-notch service.
          </p>

          <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-8 rounded-xl shadow-sm">
            <h3 className="text-xl font-bold text-gray-900 mb-6">
              Benefits of Being a Blitzer
            </h3>
            <div className="grid md:grid-cols-3 gap-6">
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <div className="w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center mb-4">
                  <span className="text-[#E54C4C] text-xl">💰</span>
                </div>
                <h4 className="font-semibold text-gray-900 mb-2">
                  Competitive Pay
                </h4>
                <p className="text-gray-600 text-sm">
                  Earn attractive rates for your dedication and service
                </p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm">
                <div className="w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center mb-4">
                  <span className="text-[#E54C4C] text-xl">🕒</span>
                </div>
                <h4 className="font-semibold text-gray-900 mb-2">
                  Flexible Hours
                </h4>
                <p className="text-gray-600 text-sm">
                  Be your own boss and work according to your schedule
                </p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm">
                <div className="w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center mb-4">
                  <span className="text-[#E54C4C] text-xl">🚀</span>
                </div>
                <h4 className="font-semibold text-gray-900 mb-2">
                  Growth Opportunity
                </h4>
                <p className="text-gray-600 text-sm">
                  Be part of a fast-growing company with endless possibilities
                </p>
              </div>
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Experience the Blitzo Difference
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            The <span className="font-semibold">Blitzers</span> are the backbone
            of Blitzo's success, ensuring we deliver on our promise of speed,
            reliability, and care. Next time you use Blitzo, remember the
            dedicated riders who make it all possible. Ready to meet your
            delivery heroes? Download the Blitzo app today and experience
            service like never before!
          </p>

          <div className="bg-[#E54C4C] bg-opacity-5 p-6 rounded-lg mt-8">
            <p className="text-xl font-semibold text-[#E54C4C] text-center">
              Blitzo: Built for the speed and unpredictability of modern life.
            </p>
          </div>
        </article>
      </div>

      <CTA />
      <Footer />
    </div>
  );
};

export default Blog3;
