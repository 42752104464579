import React, { useEffect } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
import CTA from "./CTA";
import { blogPosts } from "./Blogs/BlogData";

function Blog() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Navbar />
      <div className="flex-1 pt-32 pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold font-poppins bg-gradient-to-r from-[#E54C4C] to-[#ff6b6b] bg-clip-text text-transparent inline-block mb-12">
            Latest Posts
          </h1>

          {/* Featured Post */}
          <div className="mb-16">
            <div
              className="grid grid-cols-1 lg:grid-cols-2 gap-8 bg-white rounded-xl shadow-sm p-6 cursor-pointer hover:shadow-md transition-all duration-200"
              onClick={() => navigate(blogPosts[0].path)}
            >
              <div className="aspect-[4/3] relative overflow-hidden rounded-lg">
                <img
                  src={blogPosts[0].media}
                  alt={blogPosts[0].title}
                  className="w-full h-full object-contain bg-gray-50"
                />
              </div>
              <div className="flex flex-col justify-center lg:pr-6">
                <h2 className="text-2xl font-bold mb-4 text-gray-900">
                  {blogPosts[0].title}
                </h2>
                <p className="text-gray-600 mb-4 line-clamp-3">
                  {blogPosts[0].excerpt}
                </p>
                <div className="text-sm text-gray-500">
                  <span>{blogPosts[0].date}</span>
                  <span className="mx-2">•</span>
                  <span>{blogPosts[0].location}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Grid of Other Posts */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {blogPosts.slice(1).map((post, index) => (
              <div
                key={index}
                className="bg-white rounded-xl shadow-sm p-6 cursor-pointer hover:shadow-md transition-all duration-200"
                onClick={() => post.path && navigate(post.path)}
              >
                <div className="aspect-video relative overflow-hidden rounded-lg mb-6">
                  <img
                    src={post.media}
                    alt={post.title}
                    className="w-full h-full object-contain bg-gray-50"
                  />
                </div>
                <h2 className="text-xl font-bold mb-3 text-gray-900">
                  {post.title}
                </h2>
                <p className="text-gray-600 text-sm mb-4 line-clamp-2">
                  {post.excerpt}
                </p>
                <div className="text-sm text-gray-500">
                  <span>{post.date}</span>
                  <span className="mx-2">•</span>
                  <span>{post.location}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <CTA />
      <Footer />
    </div>
  );
}

export default Blog;
