import React, { useEffect } from "react";
import Navbar from "../navbar";
import Footer from "../footer";
import CTA from "../CTA";
import blog2Image from "./Assets/2.jpg";
import { useNavigate } from "react-router-dom";

const Blog2 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* Hero Container with Background */}
      <div className="bg-gray-50 pt-20 pb-16">
        {/* Category and Title Section */}
        <div className="pt-32 pb-8 max-w-4xl mx-auto px-4">
          <div className="mb-4">
            <button
              onClick={() => navigate("/blog")}
              className="text-sm font-medium text-[#E54C4C] hover:text-[#ff6b6b] transition-colors duration-200 flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
              Back to Blogs
            </button>
          </div>
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-4">
            Why Londoners Love Blitzo
          </h1>
          <div className="text-sm text-gray-600">
            <span>28 November 2024</span>
            <span className="mx-2">•</span>
            <span>London</span>
          </div>
        </div>

        {/* Featured Image */}
        <div className="max-w-4xl mx-auto px-4 mb-16">
          <div className="aspect-[3/2] relative overflow-hidden rounded-lg bg-white shadow-lg">
            <img
              src={blog2Image}
              alt="Blitzo Delivery in London"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </div>

      {/* Blog Content */}
      <div className="max-w-3xl mx-auto px-4 py-16">
        <article className="prose lg:prose-xl">
          <p className="text-gray-700 text-lg leading-relaxed">
            London, a city that thrives on speed and style, is falling in love
            with Blitzo, a quick and instant courier service redefining
            convenience. From busy professionals to fashion insiders, and even
            everyday Londoners with last-minute needs, Blitzo is becoming a
            trusted partner in managing life's unpredictable demands. Here's a
            glimpse into why Londoners are choosing Blitzo to make their lives
            easier:
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Real Stories from Real Users
          </h2>

          <blockquote className="border-l-4 border-[#E54C4C] pl-4 my-8 italic text-gray-700">
            "Blitzo saved my photoshoot! They delivered garments in under 30
            minutes, and I didn't miss a single shot!"
            <footer className="text-sm mt-2">– Rachel, Fashion Stylist</footer>
          </blockquote>

          <p className="text-gray-700 text-lg leading-relaxed">
            Rachel, a stylist in the fast-paced fashion industry, knows that
            time is money. When a forgotten dress could derail her photoshoot,
            Blitzo's lightning-fast service came to the rescue. Within 30
            minutes, her crisis was averted, and her shoot went off without a
            hitch.
          </p>

          <blockquote className="border-l-4 border-[#E54C4C] pl-4 my-8 italic text-gray-700">
            "I forgot my ID at home but still made it to the pub thanks to
            Blitzo. Best service ever!"
            <footer className="text-sm mt-2">– Andrea, Customer</footer>
          </blockquote>

          <p className="text-gray-700 text-lg leading-relaxed">
            Andrea, like many Londoners, found herself in a pinch. Forgetting
            her ID could have ruined her evening plans, but Blitzo stepped in.
            With our speedy delivery, she had her ID in hand in minutes.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Join the Blitzo Revolution
          </h2>

          <p className="text-gray-700 text-lg leading-relaxed">
            Blitzo is not just another courier service; it's a movement. We're
            here to ensure that your day runs smoothly, no matter what life
            throws at you. Need to send important documents to the office?
            Forgot a crucial item at home? Blitzo's same-day courier service is
            tailored to meet the unique demands of London's fast-paced
            lifestyle.
          </p>

          <p className="text-gray-700 text-lg leading-relaxed">
            Here's what sets Blitzo apart:
          </p>

          <ul className="space-y-4 my-6">
            <li className="flex items-start">
              <strong className="text-gray-900">Speed:</strong>
              <span className="ml-2">
                15-minute pickups and fast deliveries.
              </span>
            </li>
            <li className="flex items-start">
              <strong className="text-gray-900">Reliability:</strong>
              <span className="ml-2">
                A network of professional riders dedicated to meeting your
                needs.
              </span>
            </li>
            <li className="flex items-start">
              <strong className="text-gray-900">Convenience:</strong>
              <span className="ml-2">
                An intuitive app that makes placing orders effortless.
              </span>
            </li>
          </ul>

          <div className="bg-[#E54C4C] bg-opacity-5 p-6 rounded-lg mt-8">
            <p className="text-xl font-semibold text-[#E54C4C] text-center">
              Blitzo: Built for the speed and unpredictability of modern life.
            </p>
          </div>
        </article>
      </div>

      <CTA />
      <Footer />
    </div>
  );
};

export default Blog2;
