import React, { useEffect } from "react";
import Navbar from "../navbar";
import Footer from "../footer";
import CTA from "../CTA";
import blog6Image from "./Assets/6.jpg";
import { useNavigate } from "react-router-dom";

const Blog6 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* Hero Container with Background */}
      <div className="bg-gray-50 pt-20 pb-16">
        {/* Category and Title Section */}
        <div className="pt-32 pb-8 max-w-4xl mx-auto px-4">
          <div className="mb-4">
            <button
              onClick={() => navigate("/blog")}
              className="text-sm font-medium text-[#E54C4C] hover:text-[#ff6b6b] transition-colors duration-200 flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
              Back to Blogs
            </button>
          </div>
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-4">
            What is Last Mile: What It Is and Why It Matters
          </h1>
          <div className="text-sm text-gray-600">
            <span>15 November 2024</span>
            <span className="mx-2">•</span>
            <span>London</span>
          </div>
        </div>

        {/* Featured Image */}
        <div className="max-w-4xl mx-auto px-4 mb-16">
          <div className="aspect-[3/2] relative overflow-hidden rounded-lg bg-white shadow-lg">
            <img
              src={blog6Image}
              alt="Last Mile Delivery"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </div>

      {/* Blog Content */}
      <div className="max-w-3xl mx-auto px-4 py-16">
        <article className="prose lg:prose-xl">
          <p className="text-gray-700 text-lg leading-relaxed">
            In the world of logistics, the term "last mile" refers to the final
            leg of the delivery process—when a package is transported to its
            final destination, whether that's a customer's doorstep, a business,
            or another specified location. While it may be the shortest part of
            the journey, the last mile is often the most complex and crucial
            step in the entire supply chain.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            The Challenges of Last-Mile Delivery
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            The last mile poses unique challenges for logistics providers due to
            its unpredictable and resource-intensive nature. Factors such as
            traffic congestion, narrow streets, and the need to make multiple
            stops can complicate the process. Additionally, the growing demand
            for same-day or even instant deliveries adds pressure to optimise
            efficiency without compromising quality.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Why Last Mile Matters
          </h2>

          <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-8 rounded-xl shadow-sm mb-8">
            <div className="space-y-8">
              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">👁️</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Customer Experience
                  </h4>
                  <p className="text-gray-600">
                    The last mile is the most visible part of the delivery
                    journey for customers. A timely and smooth delivery can
                    enhance the customer's experience and build trust in the
                    brand.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">💰</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Cost Impact
                  </h4>
                  <p className="text-gray-600">
                    Last-mile delivery accounts for a significant portion of
                    logistics costs, often exceeding 50% of the total expenses.
                    Optimising this step is essential for competitive pricing.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">🌍</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Environmental Impact
                  </h4>
                  <p className="text-gray-600">
                    Inefficient last-mile operations contribute to higher carbon
                    emissions. Sustainable practices are becoming critical to
                    reduce the environmental footprint.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            How Blitzo Excels in Last-Mile Delivery
          </h2>

          <div className="bg-gray-50 p-8 rounded-xl my-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">
              Our Innovative Solutions
            </h3>
            <div className="space-y-4">
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">✓</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Strategic Rider Placement
                  </h4>
                  <p className="text-gray-600">
                    With riders stationed across London, Blitzo ensures fast
                    pickups and efficient deliveries.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">✓</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Real-Time Tracking
                  </h4>
                  <p className="text-gray-600">
                    Customers can monitor their package's journey in real time,
                    offering transparency and peace of mind.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">✓</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Route Optimization
                  </h4>
                  <p className="text-gray-600">
                    We use advanced systems to determine the fastest and most
                    efficient routes, minimising delays and reducing operational
                    costs.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            The Future of Last-Mile Logistics
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            As e-commerce continues to grow, the importance of last-mile
            delivery will only increase. At Blitzo, we are committed to
            providing reliable, sustainable, and customer-centric services in
            the last mile industry.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Experience Blitzo's Last-Mile Excellence
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Discover how Blitzo is transforming last-mile delivery into a
            seamless, efficient, and customer-friendly experience. Whether it's
            a personal package or a business shipment, Blitzo ensures every
            delivery is handled with care and precision. Download the Blitzo app
            today and experience the difference firsthand.
          </p>

          <div className="bg-[#E54C4C] bg-opacity-5 p-6 rounded-lg mt-8">
            <p className="text-xl font-semibold text-[#E54C4C] text-center">
              Blitzo: Built for the speed and unpredictability of modern life.
            </p>
          </div>
        </article>
      </div>

      <CTA />
      <Footer />
    </div>
  );
};

export default Blog6;
