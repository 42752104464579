import React, { useEffect } from "react";
import Navbar from "../navbar";
import Footer from "../footer";
import CTA from "../CTA";
import blog5Image from "./Assets/5.jpg";
import { useNavigate } from "react-router-dom";

const Blog5 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* Hero Container with Background */}
      <div className="bg-gray-50 pt-20 pb-16">
        {/* Category and Title Section */}
        <div className="pt-32 pb-8 max-w-4xl mx-auto px-4">
          <div className="mb-4">
            <button
              onClick={() => navigate("/blog")}
              className="text-sm font-medium text-[#E54C4C] hover:text-[#ff6b6b] transition-colors duration-200 flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
              Back to Blogs
            </button>
          </div>
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-4">
            AI in Action: Transforming Logistics with Blitzo's Cutting-Edge Tech
          </h1>
          <div className="text-sm text-gray-600">
            <span>18 November 2024</span>
            <span className="mx-2">•</span>
            <span>London</span>
          </div>
        </div>

        {/* Featured Image */}
        <div className="max-w-4xl mx-auto px-4 mb-16">
          <div className="aspect-[3/2] relative overflow-hidden rounded-lg bg-white shadow-lg">
            <img
              src={blog5Image}
              alt="AI-Powered Logistics"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </div>

      {/* Blog Content */}
      <div className="max-w-3xl mx-auto px-4 py-16">
        <article className="prose lg:prose-xl">
          <p className="text-gray-700 text-lg leading-relaxed">
            The world of logistics is undergoing a transformation, driven by
            advancements in technology. At the forefront of this revolution is
            artificial intelligence (AI), a tool that is reshaping how goods are
            transported, tracked, and delivered. At Blitzo, we've embraced these
            innovations to ensure efficiency, reliability, and convenience for
            our customers.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Transforming Operations and Customer Experience
          </h2>

          <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-8 rounded-xl shadow-sm mb-8">
            <h3 className="text-xl font-bold text-gray-900 mb-6">
              AI-Powered Solutions
            </h3>
            <div className="space-y-6">
              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">🗺️</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Smart Route Planning
                  </h4>
                  <p className="text-gray-600">
                    We use systems to analyse real-time traffic conditions,
                    weather patterns, and delivery priorities to optimize routes
                    for maximum efficiency.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">📊</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Real-Time Analytics
                  </h4>
                  <p className="text-gray-600">
                    Continuous data processing allows us to adapt to changes
                    instantly, ensuring consistent service quality.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">🔒</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Enhanced Security
                  </h4>
                  <p className="text-gray-600">
                    Advanced fraud detection algorithms and verification
                    processes protect your deliveries and data.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p className="text-gray-700 text-lg leading-relaxed">
            From real-time tracking to precise delivery time estimates,
            AI-powered tools provide customers with transparency and control,
            building trust and satisfaction. Simultaneously, AI strengthens
            security measures through fraud detection algorithms and
            verification processes, protecting sensitive deliveries and customer
            data.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Sustainability and the Future of Logistics
          </h2>

          <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-8 rounded-xl shadow-sm mb-8">
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                    <span className="text-[#E54C4C] text-xl">🌱</span>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-900 text-lg mb-2">
                      Reduced Carbon Footprint
                    </h4>
                    <p className="text-gray-600">
                      Smart routing algorithms minimize distance traveled,
                      significantly reducing our carbon emissions.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                    <span className="text-[#E54C4C] text-xl">⚡</span>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-900 text-lg mb-2">
                      Energy Efficiency
                    </h4>
                    <p className="text-gray-600">
                      Predictive maintenance and optimized operations reduce
                      energy consumption across our network.
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                    <span className="text-[#E54C4C] text-xl">🔄</span>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-900 text-lg mb-2">
                      Future Technologies
                    </h4>
                    <p className="text-gray-600">
                      Exploring innovative solutions like automated warehousing
                      and eco-friendly delivery methods.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                    <span className="text-[#E54C4C] text-xl">📊</span>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-900 text-lg mb-2">
                      Data-Driven Sustainability
                    </h4>
                    <p className="text-gray-600">
                      Using analytics to continuously improve and measure our
                      environmental impact.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="text-gray-700 text-lg leading-relaxed">
            By optimizing delivery operations, AI helps reduce emissions and
            supports greener logistics solutions. Technologies such as
            predictive maintenance and efficient routing contribute to
            environmental conservation. As AI technology evolves, its potential
            applications in logistics expand, from automated warehousing to
            drone deliveries. Companies that leverage these advancements will
            continue to lead the way in creating efficient and customer-centric
            logistics solutions.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Experience the Future of Logistics
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            AI is not just a tool for innovation—it's a gateway to better
            logistics. At Blitzo, we are proud to be part of this evolution,
            offering services that are fast, reliable, and designed for the
            modern world. Download the Blitzo app today and experience the power
            of AI-driven logistics firsthand.
          </p>

          <div className="bg-[#E54C4C] bg-opacity-5 p-6 rounded-lg mt-8">
            <p className="text-xl font-semibold text-[#E54C4C] text-center">
              Blitzo: Built for the speed and unpredictability of modern life.
            </p>
          </div>
        </article>
      </div>

      <CTA />
      <Footer />
    </div>
  );
};

export default Blog5;
