import blog1Image from "./Assets/1.JPG";
import blog2Image from "./Assets/2.jpg";
import blog3Image from "./Assets/3.JPG";
import blog4Image from "./Assets/4.jpeg";
import blog5Image from "./Assets/5.jpg";
import blog6Image from "./Assets/6.jpg";
import blog7Image from "./Assets/7.jpg";
import blog8Image from "./Assets/8.JPG";

export const blogPosts = [
  {
    title:
      "Blitzo: Revolutionising Same-Day Deliveries with Speed and Simplicity",
    excerpt:
      "In today's fast-paced world, the demand for swift and reliable delivery services has never been higher. Blitzo is at the forefront of this revolution, offering same-day deliveries that combine speed with simplicity, ensuring that your packages arrive promptly and securely.",
    media: blog1Image,
    type: "image",
    path: "/blog/blog1",
    date: "28 November 2024",
    location: "London",
  },
  {
    title: "Why Londoners Love Blitzo",
    excerpt:
      "London, a city that thrives on speed and style, is falling in love with Blitzo, a quick and instant courier service redefining convenience. From busy professionals to fashion insiders, and even everyday Londoners with last-minute needs, Blitzo is becoming a trusted partner in managing life's unpredictable demands.",
    media: blog2Image,
    type: "image",
    path: "/blog/blog2",
    date: "7 December 2024",
    location: "London",
  },
  {
    title: "Meet the Blitzers: The Backbone of Blitzo's Success",
    excerpt:
      "At Blitzo, our delivery riders are the beating heart of our operation. They are the ones ensuring that packages are delivered on time, every time. But what makes our riders special?",
    media: blog3Image,
    type: "image",
    path: "/blog/blog3",
    date: "4 December 2024",
    location: "London",
  },
  {
    title: "How Blitzo Is Setting New Standards Of Delivery",
    excerpt:
      "Gone are the days when delivery services meant waiting hours or even days for your package to arrive. In a city as dynamic as London, speed and efficiency are non-negotiable. That's where Blitzo comes in.",
    media: blog4Image,
    type: "image",
    path: "/blog/blog4",
    date: "2 December 2024",
    location: "London",
  },
  {
    title:
      "AI in Action: Transforming Logistics with Blitzo's Cutting-Edge Tech",
    excerpt:
      "The world of logistics is undergoing a transformation, driven by advancements in technology. At the forefront of this revolution is artificial intelligence (AI), a tool that is reshaping how goods are transported, tracked, and delivered.",
    media: blog5Image,
    type: "image",
    path: "/blog/blog5",
    category: "Technology",
    date: "30 November 2024",
    location: "London",
  },
  {
    title: "What is Last Mile: What It Is and Why It Matters",
    excerpt:
      "In the world of logistics, the term 'last mile' refers to the final leg of the delivery process—when a package is transported to its final destination. While it may be the shortest part of the journey, it's often the most complex and crucial step.",
    media: blog6Image,
    type: "image",
    path: "/blog/blog6",
    date: "28 November 2024",
    location: "London",
  },
  {
    title: "Blitzo's Green Revolution: Driving Towards a Sustainable London",
    excerpt:
      "Sustainability isn't just an option—it's a responsibility. In a world grappling with climate change and environmental challenges, Blitzo is proud to play its part in London's green revolution.",
    media: blog7Image,
    type: "image",
    path: "/blog/blog7",
    date: "26 November 2024",
    location: "London",
  },
  {
    title:
      "Delivering Style: How Blitzo is Solving Challenges in the Fashion Industry",
    excerpt:
      "The fashion industry is one of the most dynamic and fast-paced sectors, operating on tight schedules and demanding perfection at every turn. At Blitzo, we understand the unique challenges faced by stylists, designers, and retailers.",
    media: blog8Image,
    type: "image",
    path: "/blog/blog8",
    date: "25 November 2024",
    location: "London",
  },
];
