import React, { useEffect } from "react";
import Navbar from "../navbar";
import Footer from "../footer";
import CTA from "../CTA";
import blog8Image from "./Assets/8.JPG";
import { useNavigate } from "react-router-dom";

const Blog8 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* Hero Container with Background */}
      <div className="bg-gray-50 pt-20 pb-16">
        {/* Category and Title Section */}
        <div className="pt-32 pb-8 max-w-4xl mx-auto px-4">
          <div className="mb-4">
            <button
              onClick={() => navigate("/blog")}
              className="text-sm font-medium text-[#E54C4C] hover:text-[#ff6b6b] transition-colors duration-200 flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
              Back to Blogs
            </button>
          </div>
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-4">
            Delivering Style: How Blitzo is Solving Challenges in the Fashion
            Industry
          </h1>
          <div className="text-sm text-gray-600">
            <span>10 November 2024</span>
            <span className="mx-2">•</span>
            <span>London</span>
          </div>
        </div>

        {/* Featured Image */}
        <div className="max-w-4xl mx-auto px-4 mb-16">
          <div className="aspect-[3/2] relative overflow-hidden rounded-lg bg-white shadow-lg">
            <img
              src={blog8Image}
              alt="Fashion Industry Delivery"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </div>

      {/* Blog Content */}
      <div className="max-w-3xl mx-auto px-4 py-16">
        <article className="prose lg:prose-xl">
          <p className="text-gray-700 text-lg leading-relaxed">
            The fashion industry is one of the most dynamic and fast-paced
            sectors, operating on tight schedules and demanding perfection at
            every turn. At Blitzo, we understand the unique challenges faced by
            stylists, designers, and retailers, and we're here to keep things
            moving seamlessly.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Supporting Tight Deadlines with Reliable Delivery
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            From garments needed for last-minute photoshoots to accessories for
            high-profile events, Blitzo has become the trusted partner for
            fashion professionals across London. Our 15-minute pickup promise
            ensures that deliveries are made swiftly, helping fashion teams
            stick to their schedules without compromising on quality.
          </p>

          <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-8 rounded-xl shadow-sm my-8">
            <h3 className="text-xl font-bold text-gray-900 mb-6">
              Fashion Industry Solutions
            </h3>
            <div className="space-y-8">
              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">⚡</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Swift Response
                  </h4>
                  <p className="text-gray-600">
                    15-minute pickup for urgent fashion deliveries, ensuring
                    your timeline stays on track.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">👗</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    Careful Handling
                  </h4>
                  <p className="text-gray-600">
                    Specialized care for delicate garments and accessories,
                    maintaining their pristine condition.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C] text-xl">📍</span>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 text-xl mb-2">
                    London-Wide Coverage
                  </h4>
                  <p className="text-gray-600">
                    Comprehensive coverage across London's fashion hubs and
                    studios.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Multi-Drop Efficiency
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Time is of the essence in the fashion world, and our multi-drop
            feature is designed to meet this need. Stylists can send multiple
            pieces to different locations in a single booking, saving time and
            effort while ensuring that every item reaches its destination
            promptly and efficiently. Whether it's delivering dresses to a
            studio or shoes to a catwalk, Blitzo makes it effortless.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Real-Time Tracking for Peace of Mind
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Fashion professionals need assurance that their valuable items are
            handled with care and precision. With our real-time tracking, you
            can monitor your package's journey from pickup to delivery. This
            transparency builds confidence and allows teams to focus on creating
            stunning looks without worrying about logistics.
          </p>

          <div className="bg-gray-50 p-8 rounded-xl my-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">
              Why Fashion Professionals Trust Blitzo
            </h3>
            <div className="space-y-4">
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">✓</span>
                </div>
                <div>
                  <p className="text-gray-600">
                    Specialized handling for high-value fashion items
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">✓</span>
                </div>
                <div>
                  <p className="text-gray-600">
                    Reliable service for time-sensitive deliveries
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-[#E54C4C] bg-opacity-10 rounded-full flex items-center justify-center">
                  <span className="text-[#E54C4C]">✓</span>
                </div>
                <div>
                  <p className="text-gray-600">
                    Professional and experienced delivery team
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Setting New Standards in Fashion Logistics
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            The fast-paced nature of fashion demands a logistics partner that
            can keep up. Blitzo's commitment to speed, reliability, and customer
            satisfaction has made us the go-to courier for fashion professionals
            across London. We're proud to support creativity by ensuring that
            the logistics side of things runs smoothly.
          </p>

          <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">
            Experience the Blitzo Difference
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Whether you're a stylist coordinating a photoshoot, a designer
            unveiling a new collection, or a retailer managing urgent
            deliveries, Blitzo is here to simplify your logistics. Download the
            Blitzo app today and discover how we're transforming delivery
            services for the fashion industry.
          </p>

          <div className="bg-[#E54C4C] bg-opacity-5 p-6 rounded-lg mt-8">
            <p className="text-xl font-semibold text-[#E54C4C] text-center">
              Blitzo: Built for the speed and unpredictability of modern life.
            </p>
          </div>
        </article>
      </div>

      <CTA />
      <Footer />
    </div>
  );
};

export default Blog8;
