import React, { useEffect } from "react";
import {
  Clock,
  Package,
  ChevronRight,
  Shield,
  Globe,
  ArrowUpRight,
  Check,
  Code,
  PhoneCall,
} from "lucide-react";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import googlePlayBadge from "../Images/google-play-badge.png";
import appStoreBadge from "../Images/app-store-badge.png";
import shopifyLogo from "../Images/shopify_glyph.png";
import BrandSlider from "../components/BrandSlider";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import CTA from "../components/CTA";
import { getStoreLink } from "../utils/deviceDetection";
import TrustPilot from "../Images/TrustPilot.png";

const TypewriterText = styled.div`
  overflow: hidden;
  border-right: 2px solid #e54c4c;
  white-space: nowrap;
  margin: 0 auto;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: #e54c4c;
    }
  }
`;

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out",
      once: true,
    });
  }, []);

  const handleBookNow = () => {
    window.location.href = getStoreLink();
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Navbar */}
      <Navbar />

      {/* Hero Section */}
      <div className="relative pt-16 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-gray-100 z-0" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-10 pb-10 relative z-10">
          {/* Alert Banner */}
          <div className="mb-6 max-w-lg mx-auto px-4 py-3 rounded-lg bg-[#E54C4C]/5 border border-[#E54C4C]/20 text-[#E54C4C] text-center overflow-hidden text-xs sm:text-lg md:text-xl">
            <TypewriterText>
              Trusted by London's leading Fashion PR, Stylists.
            </TypewriterText>
          </div>

          {/* Hero Main Content */}
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-gray-900 mb-6 leading-tight">
              London's Fastest
              <br />
              <span className="text-[#E54C4C] relative inline-block sm:after:content-[''] sm:after:absolute sm:after:left-0 sm:after:bottom-0 sm:after:h-1 sm:after:bg-[#E54C4C]/20 sm:after:w-full sm:hover:after:bg-[#E54C4C] sm:after:transition-colors">
                Same-Day Courier
              </span>
              <br />
              For Fashion
            </h1>
            <p className="md:text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Hyperlocal Same Day Courier in London powered by AI. We deliver
              High Value items in minutes using our trained & reliable EV fleet.
            </p>

            <div className="max-w-8xl mx-auto">
              {/* Updated Layout Section */}
              <div className="flex flex-col space-y-8">
                {/* Combined Row for CTAs, Badges, and Brands */}
                <div className="flex flex-col lg:flex-row justify-between items-center gap-12 px-4">
                  {/* Left - Badges and Integrations in 2x2 Grid */}
                  <div className="hidden sm:grid grid-cols-2 gap-4 lg:w-1/4">
                    <img
                      src={appStoreBadge}
                      alt="Download on App Store"
                      className="h-12"
                    />
                    <img
                      src={googlePlayBadge}
                      alt="Get it on Google Play"
                      className="h-12"
                    />
                    <div className="flex items-center gap-2 px-3 py-2 bg-gray-50 rounded-lg">
                      <img
                        src={shopifyLogo}
                        alt="Shopify Plugin"
                        className="h-12"
                      />
                      <span className="text-lg text-gray-600">Shopify</span>
                    </div>
                    <div className="flex items-center gap-2 px-3 py-2 bg-gray-50 rounded-lg">
                      <Code className="text-[#E54C4C]" size={36} />
                      <span className="text-lg text-gray-600">API</span>
                    </div>
                  </div>

                  {/* Middle Side - CTAs */}
                  <div className="flex flex-col sm:flex-row gap-4 lg:mx-auto">
                    <button
                      onClick={handleBookNow}
                      className="bg-[#E54C4C] text-white px-8 py-4 rounded-lg hover:bg-[#E54C4C]/90 transition-all shadow-xl shadow-[#E54C4C]/10 flex items-center justify-center group"
                    >
                      Book a Delivery
                      <ChevronRight
                        className="ml-2 group-hover:translate-x-1 transition-transform"
                        size={20}
                      />
                    </button>
                    <button
                      onClick={() =>
                        (window.location.href =
                          "mailto:sundar.arvind@blitzo.co.ul")
                      }
                      className="border border-gray-200 bg-white text-gray-600 px-8 py-4 rounded-lg hover:border-[#E54C4C] hover:text-[#E54C4C] transition-colors flex items-center justify-center"
                    >
                      Enterprise Solutions{" "}
                      <ArrowUpRight className="ml-2" size={16} />
                    </button>
                  </div>

                  {/* Right Side - Brand Slider */}
                  <div className="lg:w-1/4">
                    <BrandSlider />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Add this CSS */}
          <style jsx>{`
            @keyframes slideBrands {
              0%,
              20% {
                opacity: 0;
                transform: translateY(20px);
              }
              25%,
              45% {
                opacity: 1;
                transform: translateY(0);
              }
              50%,
              100% {
                opacity: 0;
                transform: translateY(-20px);
              }
            }
          `}</style>
        </div>
      </div>

      {/* Stats Section */}
      <div className="border-y border-gray-100 bg-white pb-16 pt-8">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Grid Layout */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
            {/* Speed Metric */}
            <div
              className="text-center p-4 sm:p-6 rounded-xl bg-white shadow-md hover:shadow-lg transition-shadow duration-300"
              data-aos="fade-up"
            >
              <div className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-[#E54C4C] to-[#ff6b6b] bg-clip-text text-transparent">
                9 min
              </div>
              <p className="text-gray-600 text-sm md:text-base mt-2">
                Average Pickup Speed
              </p>
            </div>

            {/* Multi-Drop Price Metric */}
            <div
              className="text-center p-4 sm:p-6 rounded-xl bg-white shadow-md hover:shadow-lg transition-shadow duration-300"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="text-4xl md:text-5xl font-bold text-[#E54C4C]">
                £5
                <span className="text-base text-gray-500 ml-1">per stop*</span>
              </div>
              <p className="text-gray-600 text-sm md:text-base mt-2">
                Multi-Drop/Multi-Pickup
              </p>
              <p className="text-xs text-gray-500">*Starting price</p>
            </div>

            {/* Value Delivered Metric */}
            <div
              className="text-center p-4 sm:p-6 rounded-xl bg-white shadow-md hover:shadow-lg transition-shadow duration-300"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-[#E54C4C] to-[#ff6b6b] bg-clip-text text-transparent">
                £1M+
              </div>
              <p className="text-gray-600 text-sm md:text-base mt-2">
                Worth of Garments Delivered Securely
              </p>
            </div>

            {/* Success Rate Metric */}
            <div
              className="text-center p-4 sm:p-6 rounded-xl bg-white shadow-md hover:shadow-lg transition-shadow duration-300"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-4xl md:text-5xl font-bold text-[#E54C4C]">
                100%
              </div>
              <p className="text-gray-600 text-sm md:text-base mt-2">
                Delivery Success Rate
              </p>
              <p className="text-xs text-gray-500">Zero Thefts or Damages</p>
            </div>
          </div>
        </div>
      </div>

      {/* Core Advantages Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16" data-aos="fade-up">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Same-Day? More like Same-Hour.
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              London's most trusted same-day courier for high-value fashion
              items, combining speed, security, and sustainability.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div
              className="bg-white p-8 rounded-xl shadow-lg shadow-gray-100/50 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <div className="bg-[#E54C4C]/5 w-12 h-12 rounded-lg flex items-center justify-center mb-6">
                <Clock className="text-[#E54C4C]" size={24} />
              </div>
              <h3 className="text-xl font-semibold mb-3">
                Guranteed 15-Minute Pickup
              </h3>
              <p className="text-gray-600">
                London's fastest pickup times with real-time tracking. Perfect
                for time-critical fashion deliveries and sample returns.
              </p>
            </div>

            <div
              className="bg-white p-8 rounded-xl shadow-lg shadow-gray-100/50 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="bg-[#E54C4C]/5 w-12 h-12 rounded-lg flex items-center justify-center mb-6">
                <Shield className="text-[#E54C4C]" size={24} />
              </div>
              <h3 className="text-xl font-semibold mb-3">
                Garment-Safe Transport
              </h3>
              <p className="text-gray-600">
                Specialized in handling high-value fashion items. Professional
                riders trained in garment care and secure handling.
              </p>
            </div>

            <div
              className="bg-white p-8 rounded-xl shadow-lg shadow-gray-100/50 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="bg-[#E54C4C]/5 w-12 h-12 rounded-lg flex items-center justify-center mb-6">
                <Globe className="text-[#E54C4C]" size={24} />
              </div>
              <h3 className="text-xl font-semibold mb-3">
                Multi-Stop Excellence
              </h3>
              <p className="text-gray-600">
                Industry-leading multi-drop and multi-pickup technology and
                dispatch. Perfect for fashion shoots, PR samples, and retail
                logistics.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="pb-14 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              How It Works
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Simple, fast, and reliable delivery process designed for fashion
              professionals.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            {/* Placeholder for App Mockup */}
            <div
              className="w-full h-[500px] rounded-lg flex items-center justify-center"
              data-aos="fade-right"
            >
              <img
                src={require("../Images/mockup.png")}
                alt="Blitzo App Mockup"
                className="w-full h-full object-cover rounded-lg shadow-lg"
              />
            </div>

            {/* Steps */}
            <div className="space-y-12" data-aos="fade-left">
              {/* Step 1 */}
              <div className="flex items-start space-x-6">
                <div
                  className="bg-[#E54C4C] text-white w-12 h-12 rounded-full flex items-center justify-center shadow-lg"
                  style={{
                    fontSize: "1.25rem",
                    lineHeight: "1.25rem",
                    minWidth: "3rem",
                    minHeight: "3rem",
                  }}
                >
                  1
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">
                    Book in Seconds
                  </h3>
                  <p className="text-gray-600">
                    Enter pickup and drop-off locations, you can add up to 20.
                    Enter parcel contents, mention any special instructions and
                    pay securely.
                  </p>
                </div>
              </div>

              {/* Step 2 */}
              <div className="flex items-start space-x-6">
                <div
                  className="bg-[#E54C4C] text-white w-12 h-12 rounded-full flex items-center justify-center shadow-lg"
                  style={{
                    fontSize: "1.25rem",
                    lineHeight: "1.25rem",
                    minWidth: "3rem",
                    minHeight: "3rem",
                  }}
                >
                  2
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Quick Pickup</h3>
                  <p className="text-gray-600">
                    Our trained EV riders arrive within 15 minutes for secure,
                    professional pickup.
                  </p>
                </div>
              </div>

              {/* Step 3 */}
              <div className="flex items-start space-x-6">
                <div
                  className="bg-[#E54C4C] text-white w-12 h-12 rounded-full flex items-center justify-center shadow-lg"
                  style={{
                    fontSize: "1.25rem",
                    lineHeight: "1.25rem",
                    minWidth: "3rem",
                    minHeight: "3rem",
                  }}
                >
                  3
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">
                    Track Real-Time
                  </h3>
                  <p className="text-gray-600">
                    Monitor your deliveries in real-time with live tracking of
                    the rider and parcel status. Get notifications and photo
                    proofs on every step.
                  </p>
                </div>
              </div>
              {/* App Store Badges */}
              <div className="mt-16 text-center" data-aos="fade-up">
                <p className="text-lg text-gray-600 mb-6">
                  Download our app to get started
                </p>
                <div className="flex justify-center items-center gap-4 flex-wrap">
                  <a
                    href="https://apps.apple.com/gb/app/blitzo/id6502848319"
                    className="hover:opacity-80 transition-opacity"
                  >
                    <img
                      src={appStoreBadge}
                      alt="Download on App Store"
                      className="h-12"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.blitzo&pcampaignid=web_share"
                    className="hover:opacity-80 transition-opacity"
                  >
                    <img
                      src={googlePlayBadge}
                      alt="Get it on Google Play"
                      className="h-12"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">
              Affordable Same-Day Deliveries at Your Fingertips
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Choose the plan that works best for your business needs. No matter
              what you choose, we will serve every delivery with the same level
              of care and attention.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <div className="bg-white p-8 rounded-xl border border-gray-200 hover:border-[#E54C4C] transition-colors">
              <div className="mb-8">
                <h3 className="text-2xl font-semibold mb-2">Pay As You Go</h3>
                <div className="text-4xl font-bold text-[#E54C4C]">
                  £3.49
                  <span className="text-base font-normal text-gray-600 ml-2">
                    base fee
                  </span>
                </div>
              </div>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <Package
                    className="text-[#E54C4C] mr-3 mt-1 flex-shrink-0"
                    size={18}
                  />
                  <span>Dynamic pricing based on distance and size</span>
                </li>
                <li className="flex items-start">
                  <Package
                    className="text-[#E54C4C] mr-3 mt-1 flex-shrink-0"
                    size={18}
                  />
                  <span>No commitment required</span>
                </li>
                <li className="flex items-start">
                  <Package
                    className="text-[#E54C4C] mr-3 mt-1 flex-shrink-0"
                    size={18}
                  />
                  <span>Instant booking via app or web</span>
                </li>
              </ul>
              <button
                onClick={handleBookNow}
                className="w-full mt-8 py-3 rounded-lg border border-[#E54C4C] text-[#E54C4C] hover:bg-[#E54C4C] hover:text-white transition-colors"
              >
                Get Started
              </button>
            </div>
            <div className="bg-[#E54C4C] p-8 rounded-xl text-white shadow-xl shadow-[#E54C4C]/20">
              <div className="mb-8">
                <h3 className="text-2xl font-semibold mb-2">Enterprise</h3>
                <div className="text-4xl font-bold">
                  Custom
                  <span className="text-base font-normal ml-2 text-white/90">
                    volume pricing
                  </span>
                </div>
              </div>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <Package
                    className="text-white mr-3 mt-1 flex-shrink-0"
                    size={18}
                  />
                  <span>Fixed fee per delivery</span>
                </li>
                <li className="flex items-start">
                  <Package
                    className="text-white mr-3 mt-1 flex-shrink-0"
                    size={18}
                  />
                  <span>Volume-based discounts</span>
                </li>
                <li className="flex items-start">
                  <Package
                    className="text-white mr-3 mt-1 flex-shrink-0"
                    size={18}
                  />
                  <span>API & Shopify integrations available</span>
                </li>
              </ul>
              <button
                onClick={() =>
                  (window.location.href = "mailto:sundar.arvind@blitzo.co.uk")
                }
                className="w-full mt-8 py-3 rounded-lg bg-white text-[#E54C4C] hover:bg-gray-50 transition-colors flex items-center justify-center"
              >
                Contact Sales <PhoneCall className="ml-2" size={16} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Add Testimonial section before Features */}
      <div className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative rounded-2xl bg-[#E54C4C]/5 p-10">
            <div className="relative z-10">
              <div className="flex flex-col md:flex-row gap-8 items-center">
                <div className="w-100 md:h-40 overflow-hidden">
                  <img
                    src={`https://avatar.iran.liara.run/username?username=Delaney+Williams`}
                    alt="Delaney Williams"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="flex-1">
                  <blockquote className="text-xl md:text-2xl font-medium text-gray-900 mb-4">
                    "Lifesaver Company. Solving such a huge gap in fashion.
                    Super quick, affordable, and reliable."
                  </blockquote>
                  <cite className="block">
                    <span className="text-lg font-semibold text-[#E54C4C] block">
                      Delaney Williams
                    </span>
                    <span className="text-gray-600">
                      Stylist & Fashion Director
                    </span>
                  </cite>
                </div>
              </div>
            </div>
            <div className="absolute inset-0 flex items-center justify-end opacity-10">
              <svg
                className="w-48 h-48 text-[#E54C4C]"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      {/* Add Fashion-specific benefits section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Built for Fashion
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Specialized features designed for stylists, PR firms, and fashion
              professionals.
              <br />
              Powered by AI and a reliable EV fleet.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div
              className="bg-white p-8 rounded-xl shadow-lg"
              data-aos="fade-right"
            >
              <h3 className="text-xl font-semibold mb-4">
                Multi-Drop and Multi-Pickup Deliveries
              </h3>
              <p className="text-gray-600 mb-6">
                Efficiently deliver garments to/from multiple locations in one
                go. Perfect for fashion shoots, events, and PR samples. Schedule
                instant collections, or pick a time slot that suits you.
              </p>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <Check className="text-[#E54C4C] mr-2" size={20} />
                  <span>Upto 20 pickups and drop-offs in one booking</span>
                </li>
                <li className="flex items-center">
                  <Check className="text-[#E54C4C] mr-2" size={20} />
                  <span>
                    Real-time tracking, notifications and photo proofs
                  </span>
                </li>
                <li className="flex items-center">
                  <Check className="text-[#E54C4C] mr-2" size={20} />
                  <span>Instant Collection or Scheduling</span>
                </li>
              </ul>
            </div>
            <div
              className="bg-white p-8 rounded-xl shadow-lg"
              data-aos="fade-left"
            >
              <h3 className="text-xl font-semibold mb-4">
                Prices and Speed that actually work
              </h3>
              <p className="text-gray-600 mb-6">
                Affordable pricing and fast delivery times that work for fashion
                professionals. No more getting ripepd off by Gophr or using 5
                Ubers to get your samples to a shoot.
              </p>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <Check className="text-[#E54C4C] mr-2" size={20} />
                  <span>Starting at £3.49 per delivery</span>
                </li>
                <li className="flex items-center">
                  <Check className="text-[#E54C4C] mr-2" size={20} />
                  <span>15-minute pickup times</span>
                </li>
                <li className="flex items-center">
                  <Check className="text-[#E54C4C] mr-2" size={20} />
                  <span>Our Riders love handling fashion items with care</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      {/* CTA Section */}
      <CTA />
      <Footer />
    </div>
  );
};

export default Home;
