import React, { useEffect } from "react";
import { Mail, Phone, Users, Building2, ArrowUpRight } from "lucide-react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import AOS from "aos";
import "aos/dist/aos.css";
import CTA from "../components/CTA";

const Contact = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
    });
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* Hero Section */}
      <div className="relative bg-gradient-to-br from-white to-gray-100 pt-40 pb-20">
        <div
          className="max-w-7xl mx-auto px-6 lg:px-8 text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1 className="text-3xl md:text-6xl md:text-7xl font-bold text-gray-900 mb-6">
            <span className="block mb-2">Let's Talk</span>
            <span className="text-[#E54C4C] relative inline-block after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-1 after:bg-[#E54C4C]/20 after:w-full hover:after:bg-[#E54C4C] after:transition-colors">
              We're Here to Help
            </span>
          </h1>
          <p className="text-xl md:text-2xl text-gray-600 max-w-3xl mx-auto mt-8 leading-relaxed">
            We believe in building relationships, not just delivering packages.
            If you need anything from us at all, from general support to complex
            Fleet as a Service solutions, we're just a call or email away.
          </p>
        </div>
      </div>

      {/* Contact Methods Grid */}
      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* General Support */}
          <div
            className="bg-white p-10 rounded-2xl border border-gray-100 shadow-sm hover:shadow-xl transition-all duration-300"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <Mail className="text-[#E54C4C] mb-6 h-12 w-12" />
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              General Support
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              Need help with a delivery or have questions about our service? Our
              friendly support team is here for you.
            </p>
            <a
              href="mailto:support@blitzo.co.uk"
              className="text-xl font-semibold text-[#E54C4C] hover:text-[#E54C4C]/80 transition-colors flex items-center"
            >
              support@blitzo.co.uk
              <ArrowUpRight className="ml-2" size={20} />
            </a>
          </div>

          {/* Business Enquiries */}
          <div
            className="bg-white p-10 rounded-2xl border border-gray-100 shadow-sm hover:shadow-xl transition-all duration-300"
            data-aos="fade-left"
            data-aos-delay="200"
          >
            <Building2 className="text-[#E54C4C] mb-6 h-12 w-12" />
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Business Enquiries
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              Looking to integrate Blitzo into your business? Want to discuss
              PoS integrations or bespoke fleet solutions? Contact our founding
              team directly.
            </p>
            <a
              href="mailto:sundar.arvind@blitzo.co.uk"
              className="text-xl font-semibold text-[#E54C4C] hover:text-[#E54C4C]/80 transition-colors flex items-center"
            >
              Email us
              <ArrowUpRight className="ml-2" size={20} />
            </a>
          </div>
        </div>
      </div>

      {/* Call Us Section */}
      <div className="bg-gray-50 py-20">
        <div
          className="max-w-7xl mx-auto px-6 lg:px-8 text-center"
          data-aos="fade-up"
        >
          <Phone className="text-[#E54C4C] h-12 w-12 mx-auto mb-6" />
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Prefer to Talk?
          </h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            We love a good conversation! Give us a call during business hours
            and we'll be happy to help.
          </p>
          <a
            href="tel:+447553848371"
            className="text-2xl font-bold text-[#E54C4C] hover:text-[#E54C4C]/80 transition-colors"
          >
            Give us a call
          </a>
        </div>
      </div>

      {/* Final CTA */}
      <CTA />

      <Footer />
    </div>
  );
};

export default Contact;
