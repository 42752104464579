import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import userTermsPDF from "../terms/userTerms.pdf";
import riderTermsPDF from "../terms/riderTerms.pdf";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import AOS from "aos";
import "aos/dist/aos.css";

// PDF worker configuration
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function Terms() {
  const [numPages, setNumPages] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const isRider = searchParams.get("type") === "rider";

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
    });
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* Hero Section */}
      <div className="relative bg-gradient-to-br from-white to-gray-100 pt-40 pb-20">
        <div
          className="max-w-7xl mx-auto px-6 lg:px-8 text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1 className="text-3xl md:text-6xl md:text-7xl font-bold text-gray-900 mb-6">
            <span className="text-[#E54C4C] relative inline-block">
              Terms & Conditions
            </span>
          </h1>
          <p className="text-xl md:text-2xl text-gray-600 max-w-3xl mx-auto mt-8 leading-relaxed">
            Our commitment to transparency and fair business practices
          </p>
        </div>
      </div>

      {/* Terms Content */}
      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-20">
        {/* Tabs */}
        <div
          className="flex justify-center mb-12 gap-4"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <button
            className={`px-8 py-4 rounded-lg font-bold transition-all duration-300 text-lg
              ${
                !isRider
                  ? "bg-[#E54C4C] text-white shadow-lg shadow-[#E54C4C]/10"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
            onClick={() => navigate("/terms")}
          >
            User Terms
          </button>
          <button
            className={`px-8 py-4 rounded-lg font-bold transition-all duration-300 text-lg
              ${
                isRider
                  ? "bg-[#E54C4C] text-white shadow-lg shadow-[#E54C4C]/10"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
            onClick={() => navigate("/terms?type=rider")}
          >
            Rider Terms
          </button>
        </div>

        {/* PDF Viewer */}
        <div
          className="bg-white rounded-2xl shadow-xl p-8"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <Document
            file={isRider ? riderTermsPDF : userTermsPDF}
            onLoadSuccess={onDocumentLoadSuccess}
            className="flex flex-col items-center"
          >
            {Array.from(new Array(numPages), (el, index) => (
              <div
                key={`page_${index + 1}`}
                className="mb-8 last:mb-0 w-full max-w-4xl mx-auto"
              >
                <Page
                  pageNumber={index + 1}
                  renderMode="canvas"
                  renderTextLayer={false}
                  width={window.innerWidth > 768 ? 800 : window.innerWidth - 64}
                  className="shadow-lg rounded-lg overflow-hidden"
                />
              </div>
            ))}
          </Document>
        </div>
      </div>

      {/* Terms Summary Section */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              Have Questions About Our Terms?
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              We're here to help explain anything that's unclear. Contact our
              support team for clarification on any of our terms and conditions.
            </p>
            <a
              href="mailto:support@blitzo.co.uk"
              className="inline-block bg-[#E54C4C] text-white px-8 py-3 rounded-lg font-bold hover:bg-[#E54C4C]/90 transition-colors"
            >
              Contact Support
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
