import React, { useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";

const TrackOrder = () => {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!trackingNumber.trim()) {
      setError("Please enter a tracking number");
      return;
    }

    // Redirect to the live tracking page
    window.location.href = `https://blitzo.co.uk/liveOrders/${trackingNumber.trim()}`;
    setError("");
  };

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      <div className="max-w-4xl mx-auto px-4 py-32">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Track Your Order
          </h1>
          <p className="text-gray-600">
            Enter your tracking number to get real-time updates on your delivery
          </p>
        </div>

        <div className="max-w-md mx-auto">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="tracking-number"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Tracking Number
              </label>
              <input
                type="text"
                id="tracking-number"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#E54C4C] focus:border-transparent"
                placeholder="Enter your tracking number"
              />
              {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
            </div>

            <button
              type="submit"
              className="w-full bg-[#E54C4C] text-white py-3 px-6 rounded-md hover:bg-[#ff6b6b] transition-colors duration-200"
            >
              Track Order
            </button>
          </form>

          <div className="mt-12 p-6 bg-gray-50 rounded-lg">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">
              Need Help?
            </h2>
            <p className="text-gray-600 mb-4">
              If you're having trouble tracking your order or have any
              questions, our customer support team is here to help.
            </p>
            <a
              href="mailto:support@blitzo.com"
              className="text-[#E54C4C] hover:text-[#ff6b6b] font-medium"
            >
              Contact Support
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TrackOrder;
